import PropTypes from "prop-types";
import { Container, Alert, AlertTitle } from "@mui/material";
import { useSelector } from "react-redux";

import { getCurrentUserRole, getCurrentUserGroup } from "src/selectors/auth.selector";

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.string,
  children: PropTypes.node
};

const useCurrentUsrGroup = () => useSelector(getCurrentUserGroup);

export default function RoleBasedGuard({ accessibleRoles, children, currentURL }) {
  const currentGroup = useCurrentUsrGroup();

  if (!currentGroup.URL.includes(currentURL)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
