import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// routes
import { PATH_DASHBOARD } from "../../routes/paths";

// components
import SvgIconStyle from "../../components/SvgIconStyle";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountIcon from "@mui/icons-material/SwitchAccount";
import PersonIcon from "@mui/icons-material/Person";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import ExtensionSharpIcon from "@mui/icons-material/ExtensionSharp";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import HomeIcon from "@mui/icons-material/Home";
import { getCurrentUserRole, getCurrentUserGroup } from "src/selectors/auth.selector";
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: "100%", height: "100%" }} />
);

const ICONS = {
  user: getIcon("ic_user"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard")
};

const getSidebarConfig = () => {
  const role = useSelector(getCurrentUserRole);
  const group = useSelector(getCurrentUserGroup);

  const [hiddenButton, setHiddenButton] = useState(group.UI);

  useEffect(() => {
    function handlePermission() {
      setHiddenButton(group.UI);
    }
    handlePermission();
  }, []);

  const sidebarConfig = [
    {
      items: [
        {
          title: "Home",
          path: PATH_DASHBOARD.home.root,
          icon: <HomeIcon />
        },
        {
          title: "Management",
          path: PATH_DASHBOARD.management.root,
          icon: <AssignmentTurnedInIcon />,
          hidden: !Object.values(hiddenButton.management).some((val) => val === true),
          children: [
            { title: "Slippage", path: PATH_DASHBOARD.management.slippage, hidden: !hiddenButton.management.slippage },
            {
              title: "Rpq Report",
              path: PATH_DASHBOARD.management.RpqReport,
              hidden: !hiddenButton.management.rpq_report
            }
          ]
        },
        {
          title: "Report",
          path: PATH_DASHBOARD.reports.root,
          icon: <AssignmentIcon />,
          hidden: !Object.values(hiddenButton.report).some((val) => val === true),
          children: [
            { title: "Overview", path: PATH_DASHBOARD.reports.overview, hidden: !hiddenButton.report.overview }
          ]
        },
        {
          title: "Search",
          path: PATH_DASHBOARD.search.root,
          icon: <ScreenSearchDesktopIcon />,
          hidden: !Object.values(hiddenButton.search).some((val) => val === true),
          children: [
            { title: "Campaign", path: PATH_DASHBOARD.search.campaign, hidden: !hiddenButton.search.campaign },
            { title: "Feed", path: PATH_DASHBOARD.search.feed, hidden: !hiddenButton.search.feed },
            { title: "Advertiser Data Control", path: PATH_DASHBOARD.search.advertiser_data_control, hidden: !hiddenButton.search.advertiser_data_control },
            { title: "Report", path: PATH_DASHBOARD.search.feed_report, hidden: !hiddenButton.search.report },
            {
              title: "Queries Report",
              path: PATH_DASHBOARD.search.queries_report,
              hidden: !hiddenButton.search.queries_report
            },
            {
              title: "Blacklisted Queries",
              path: PATH_DASHBOARD.search.blacklisted_queries,
              hidden: !hiddenButton.search.blacklisted_queries
            }
          ]
        },
        {
          title: "Products",
          path: PATH_DASHBOARD.products.root,
          icon: <ExtensionSharpIcon />,
          hidden: !Object.values(hiddenButton.product).some((val) => val === true),
          children: [
            { title: "Campaign", path: PATH_DASHBOARD.products.campaign, hidden: !hiddenButton.product.campaign },
            { title: "Offer", path: PATH_DASHBOARD.products.feed, hidden: !hiddenButton.product.offer },
            { title: "Report", path: PATH_DASHBOARD.products.report, hidden: !hiddenButton.product.report }
          ]
        },
        {
          title: "Feed balancer",
          path: PATH_DASHBOARD.feedbalancer.root,
          icon: ICONS.analytics,
          hidden: !Object.values(hiddenButton.feed_balancer).some((val) => val === true),
          children: [
            {
              title: "Geo groups",
              path: PATH_DASHBOARD.feedbalancer.geo_groups,
              hidden: !hiddenButton.feed_balancer.geo_groups
            },
            {
              title: "Redirect groups",
              path: PATH_DASHBOARD.feedbalancer.redirect_groups,
              hidden: !hiddenButton.feed_balancer.redirect_groups
            },
            {
              title: "Fraud detection",
              path: PATH_DASHBOARD.feedbalancer.fraud_detection,
              hidden: !hiddenButton.feed_balancer.fraud_detection
            }
          ]
        },
        {
          title: "Account",
          path: PATH_DASHBOARD.account.root,
          hidden: !hiddenButton.account,
          icon: <AccountIcon />
        },
        {
          title: "Partner",
          path: PATH_DASHBOARD.partner.root,
          hidden: !hiddenButton.partner,
          icon: <PeopleAltIcon />
        },
        {
          title: "Balance",
          path: PATH_DASHBOARD.balance.root,
          hidden: !hiddenButton.balance,
          icon: <AssignmentIcon />
        },
        {
          title: "Logs Report",
          path: PATH_DASHBOARD.logs.root,
          hidden: !hiddenButton.logs,
          icon: <AssignmentIcon />
        },
        {
          title: "User",
          path: PATH_DASHBOARD.user.root,
          hidden: !hiddenButton.user,
          icon: <PersonIcon />
        }
      ]
    }
  ];

  return sidebarConfig;
};

export { getSidebarConfig };
