import * as campaignsTypes from "../../helper/constant/types";

const INITIAL_STATE = {
    error: null,
    IsfetchingCampaigns: false,
    Campaigns: [],
};

const Campaigns_Reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case campaignsTypes.FETCH_CAMPAIGNS_START:
            return {
                ...state,
                IsfetchingCampaigns: true
            };
        case campaignsTypes.FETCH_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                IsfetchingCampaigns: true
            };
        case campaignsTypes.FETCH_CAMPAIGNS_FAILURE:
            return {
                ...state,
                IsfetchingCampaigns: false,
                error: action.payload
            };
        case campaignsTypes.UPDATE_CAMPAIGNS:
            return {
                ...state,
                Feeds: action.payload
            };
        default:
            return { ...state };
    }
};

export default Campaigns_Reducer;
