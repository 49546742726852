// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/";

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: { root: path(ROOTS_DASHBOARD, "home") },
  reports: {
    root: path(ROOTS_DASHBOARD, "report"),
    overview: path(ROOTS_DASHBOARD, "report/overview")
    // feed_report: path(ROOTS_DASHBOARD, 'report/feed_report')
  },
  management: {
    root: path(ROOTS_DASHBOARD, "management"),
    reports: path(ROOTS_DASHBOARD, "management/reports"),
    slippage: path(ROOTS_DASHBOARD, "management/slippage"),
    revenues_edit: path(ROOTS_DASHBOARD, "management/revenues-edit"),
    RpqReport: path(ROOTS_DASHBOARD, "management/RpqReport")
  },
  search: {
    root: path(ROOTS_DASHBOARD, "search"),
    campaign: path(ROOTS_DASHBOARD, "search/campaign"),
    feed: path(ROOTS_DASHBOARD, "search/feed"),
    advertiser_data_control: path(ROOTS_DASHBOARD, "search/advertiser_data_control"),
    feed_report: path(ROOTS_DASHBOARD, "search/feed_report"),
    campaign_add: path(ROOTS_DASHBOARD, "search/campaign_add"),
    campaign_edit: path(ROOTS_DASHBOARD, "search/campaign_edit"),
    campaign_source: path(ROOTS_DASHBOARD, "search/campaign_source"),
    feed_add: path(ROOTS_DASHBOARD, "search/feed_add"),
    feed_edit: path(ROOTS_DASHBOARD, "search/feed_edit"),
    feed_hardcoded: path(ROOTS_DASHBOARD, "search/feed_hardcoded"),
    queries_report: path(ROOTS_DASHBOARD, "search/queries_report"),
    blacklisted_queries: path(ROOTS_DASHBOARD, "search/blacklisted_queries")
  },
  products: {
    root: path(ROOTS_DASHBOARD, "product"),
    campaign: path(ROOTS_DASHBOARD, "product/campaign"),
    feed: path(ROOTS_DASHBOARD, "product/feed"),
    campaign_add: path(ROOTS_DASHBOARD, "product/campaign_add"),
    campaign_edit: path(ROOTS_DASHBOARD, "product/campaign_edit"),
    campaign_source: path(ROOTS_DASHBOARD, "product/campaign_source"),
    feed_add: path(ROOTS_DASHBOARD, "product/feed_add"),
    feed_edit: path(ROOTS_DASHBOARD, "product/feed_edit"),
    feed_hardcoded: path(ROOTS_DASHBOARD, "product/feed_hardcoded"),
    report: path(ROOTS_DASHBOARD, "product/report")
  },
  feedbalancer: {
    root: path(ROOTS_DASHBOARD, "feedbalancer"),
    geo_groups: path(ROOTS_DASHBOARD, "feedbalancer/geo_groups"),
    geo_add: path(ROOTS_DASHBOARD, "feedbalancer/geo_add"),
    geo_edit: path(ROOTS_DASHBOARD, "feedbalancer/geo_edit"),
    redirect_groups: path(ROOTS_DASHBOARD, "feedbalancer/redirect_groups"),
    fraud_detection: path(ROOTS_DASHBOARD, "feedbalancer/fraud_detection")
  },
  partner: {
    root: path(ROOTS_DASHBOARD, "partner"),
    partner_edit: path(ROOTS_DASHBOARD, "partner/edit"),
    partner_add: path(ROOTS_DASHBOARD, "partner/add"),
    partner_view: path(ROOTS_DASHBOARD, "partner/view")
  },
  account: {
    root: path(ROOTS_DASHBOARD, "accounts"),
    account_edit: path(ROOTS_DASHBOARD, "accounts/edit")
  },
  balance: {
    root: path(ROOTS_DASHBOARD, "balance"),
    balance_add: path(ROOTS_DASHBOARD, "balance/add")
  },
  logs: {
    root: path(ROOTS_DASHBOARD, "logs")
  },
  affilation: {
    root: path(ROOTS_DASHBOARD, "affilation"),
    advertiser_campaign: path(ROOTS_DASHBOARD, "affilation/advertiser-campaign"),
    advertiser_add_campaign: path(ROOTS_DASHBOARD, "affilation/advertiser-add-campaign"),
    advertiser_edit_campaign: path(ROOTS_DASHBOARD, "affilation/advertiser-edit-campaign"),
    publisher_campaign: path(ROOTS_DASHBOARD, "affilation/publisher-campaign"),
    publisher_add_campaign: path(ROOTS_DASHBOARD, "affilation/publisher-add-campaign"),
    publisher_edit_campaign: path(ROOTS_DASHBOARD, "affilation/publisher-edit-campaign")
  },
  user: {
    root: path(ROOTS_DASHBOARD, "user"),
    user_add: path(ROOTS_DASHBOARD, "user/add"),
    user_edit: path(ROOTS_DASHBOARD, "user/edit")
  }
};
