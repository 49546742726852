import { map, uniqBy } from "lodash";

import * as authHelper from "./AuthHelper";

export const API_URL = process.env.REACT_APP_API_URL;
export const auth = authHelper;
export const user = authHelper;
export const call = (handler, ...data) => {
  if (handler) {
    handler(...data);
  }
};
export const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
  el.select();
  const success = document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
  return success;
};

export const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);

export const getNDayBeforeDate = (daysBefore) => {
  const d = new Date();
  d.setDate(d.getDate() - daysBefore);
  return d;
};

export function roundToTwo(num) {
  return +`${Math.round(`${num}e+2`)}e-2`;
}

export function changeNullToEmptyString(value) {
  if (value === undefined || value === null) return "";

  return value;
}

export function changeEmptyStringToNull(value) {
  if (value.trim() === "") {
    return null;
  }
  return value;
}

/**
 * Create options for select component
 *
 * @export
 * @param {Array} arr
 * @param {string} [options={ valueKey: 'id', labelKey: 'name' }]
 * @return {Array}
 */
export function mapToSelectOptions(arr, options = { valueKey: "id", labelKey: "name" }) {
  if (options instanceof Array) {
    options = { valueKey: options[0], labelKey: options[1] };
  }

  return arr.map((item) => ({
    value: item[options.valueKey],
    label: item[options.labelKey],
    name: item[options.labelKey]
  }));
}

export function emptyStringToNull(str) {
  if (typeof str === "string") {
    return str.length > 0 ? str : null;
  }

  return str ?? null;
}

/**
 * Combine geos from geo group model and return set of geos
 *
 * @param {Array} groups
 * @param {Array} geos
 */
export function combineGeosFromGeoGroups(groups, geos) {
  const geosFromGroups = map(groups, "geos").flat();

  return uniqBy([...geosFromGroups, ...geos], "iso");
}

/**
 * Yup validation for valid link
 *
 * @export
 * @param {*} message
 * @return {*}
 */
export function isValidLink(message) {
  return this.test("isValidLink", message, function validateLink(value) {
    const { path, createError } = this;
    if (!value) {
      if (path === "campaign_url") {
        return true;
      }
      if (path === "company_url") {
        return true;
      }
      return createError({ path, message: message ?? "Required" });
    }

    const regex = new RegExp(
      "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=\\{\\}]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
    );

    if (regex.test(value)) {
      return true;
    }

    return createError({ path, message: message ?? "Please provide valid link" });
  });
}

/**
 * Yup method to check valid channel range
 *
 * @param {*} message
 * @return {*}
 */
export function isValidChannelRange(message) {
  return this.test("isValidChannelRange", message, function isValidChannelRangeRule(value) {
    const { path, createError } = this;
    if (!value) {
      return createError({ path, message: message ?? "Channel range is required field" });
    }

    const [usermin = 0, usermax = 0] = value.split("-");
    if (parseInt(usermax, 10) === 0) {
      return true;
    }

    if (parseInt(usermin, 10) > parseInt(usermax, 10)) {
      return createError({ path, message: message ?? "Please provide valid channel range eg. 1-100" });
    }

    return true;
  });
}

/**
 * Yup validation method for cap validation
 *
 * @export
 * @param {*} message
 * @return {*}
 */
export function capValidation(message) {
  return this.test("CapValidation", message, (value) => {
    const { path, createError } = this;
    if (!value || value.length >= 0) {
      return true;
    }
    if (+value <= 0) {
      return createError({ path, message: message ?? "Campaign cap should be positive number" });
    }
    return true;
  });
}

/**
 * Filter options by value
 *
 * @param {array[{label: <string>, value: <string | number>}]} options
 * @param {string | number} value
 * @return {object {label: <string>, value: <string | number>}}
 */
export function findOptionsByValue(options, value) {
  const data = options.find((option) => option.value === value);
  if (data) {
    return data;
  }
  return "";
}

/**
 * Generate filter params and navigate to generated path
 */
export function generateAndSetParams(path, navigate, { account, partner, filter, showAll }) {
  const url = `${window.location.protocol}//${window.location.host}`;
  const filterParams = new URL(url);
  if (account) {
    filterParams.searchParams.append("account", account);
  }
  if (partner) {
    filterParams.searchParams.append("partner", partner);
  }
  if (filter) {
    filterParams.searchParams.append("filter", filter);
  }
  if (showAll) {
    filterParams.searchParams.append("showAll", showAll);
  }
  navigate(path + filterParams.search, { replace: true });
}

/**
 * Generate and set filter params
 */
export function generatePathFromParams(path, params) {
  const url = `${window.location.protocol}//${window.location.host}`;

  const filterParams = new URL(url);
  Object.entries(params).map(([key, val]) => filterParams.searchParams.append(key, val));

  return path + filterParams.search;
}

/**
 * Set empty string to null
 *
 * fields {object}
 */
export function setNullForEmptyFields(fields) {
  return Object.entries(fields).map(([key, value]) =>
    String(value).trim().length === 0 ? (fields[key] = null) : undefined
  );
}

/**
 * A no op function
 */
export function noop() {}
