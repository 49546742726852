import React from "react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import { useAsync } from "../../hooks/useAsync";
import CampaignService from "../../service/campaign.service";

const PublishBtn = () => {
  const onSuccessfulPublish = () => {
    toast.success("Data Published Successfully!");
  };

  const onFailurePublish = (error) => {
    toast.error(error?.message ? JSON.stringify(error.message) : "Sorry, could not publish");
  };

  const { status: isPublishing, run } = useAsync({
    onSuccess: onSuccessfulPublish,
    onError: onFailurePublish
  });

  const publishData = () => {
    if(window.confirm("Are you sure to publish the data?")){
        run(CampaignService.publishData());
    }
  };

  return (
    <div>
      <Button variant="contained" color="info" startIcon={<PublishIcon />} onClick={publishData}>
        {isPublishing === "pending" ? "Please Wait..." : "Publish Data"}
      </Button>
    </div>
  );
};

export default PublishBtn;
