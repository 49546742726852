import Http from "./httpservice";

class CampaignService {
  async fetchCampaignsList(params = {}) {
    const res = await Http.get(`campaigns`, null, params);
    return res.body;
  }

  async fetchAllCampaigns(params = {}) {
    const res = await Http.get("campaigns", null, params);
    return res.body;
  }

  async fetchCampaignsListBypartnerId(account_id, partner_id, params = {}) {
    const res = await Http.get("campaigns", null, { ...params, account_id, partner_id });
    return res.body;
  }

  async fetchCampaignsListOnlyBypartnerId(partner_id, params = {}) {
    const res = await Http.get("campaigns", null, { ...params, partner_id });
    return res.body;
  }

  async createCampaign(campaign) {
    const url = `/campaign`;
    const response = await Http.post(url, campaign);
    return response.body;
  }

  async editCampaign(campaign, campaignId) {
    const url = `/campaign/${campaignId}`;
    const response = await Http.put(url, campaign);
    return response.body;
  }

  async fetchSourceControl(campaignId) {
    const url = `/campaign/${campaignId}/source`;
    const response = await Http.get(url);
    const sourceList = response.body.data.source_control;

    return sourceList;
  }

  async deleteSource({ campaignId, sourceId }) {
    const url = `/campaign/${campaignId}/source/${sourceId}`;
    const response = await Http.destroy(url);
    return response.body;
  }

  async createSourceControl(campaignId, data) {
    const url = `/campaign/${campaignId}/source`;
    const response = await Http.post(url, data);
    return response.body;
  }

  async updateSourceControl(campaignId, source, data) {
    const url = `/campaign/${campaignId}/source/${source}`;
    const response = await Http.put(url, data);
    return response.body;
  }

  async addFeeds(campaignId, data) {
    const url = `/campaign/${campaignId}/feed`;
    const response = await Http.post(url, data);
    return response.body;
  }

  async editFeeds(campaignId, feedId, data) {
    const url = `/campaign/${campaignId}/feed/${feedId}`;
    const response = await Http.put(url, data);

    return response.body;
  }
  async deleteFeed(campaignId, feedId) {
    const url = `/campaign/${campaignId}/feed/${feedId}`;
    const response = await Http.destroy(url);

    return response.body;
  }

  async getCampaignFeedsByCampaignId(campaignId, params = {}) {
    const url = `/campaign/${campaignId}/feed`;
    const response = await Http.get(url, null, params);
    return response.body;
  }

  async getCampaignFeedsByFeedId(feedId, params = {}) {
    const url = `/campaign/feeds/${feedId}`;
    const response = await Http.get(url, null, params);
    return response.body;
  }

  async getBlockedFeedsByCampaignId(campaignId, params = {}) {
    const url = `/campaign/feeds/blocked/${campaignId}`;
    const response = await Http.get(url, null, null);
    return response.body;
  }

  async updateSourceType(campaignId, type) {
    const url = `/campaign/${campaignId}/source`;
    const response = await Http.patch(url, { list_type: type });

    return response.body;
  }

  async get(campaignId) {
    const url = `/campaign/${campaignId}`;
    const response = await Http.get(url);
    return response.body;
  }

  async deleteCampaigns(campaignId) {
    const url = `/campaign/${campaignId}`;
    const response = await Http.destroy(url);
    return response.body;
  }

  async publishData(params = {}) {
    const url = '/publish-config';
    const response = await Http.get(url, null, params);
    return response.body;
  }

  async getAllnames(params = {}) {
    const url = `/campaigns-names`;
    const response = await Http.get(url, null, params);
    return response.body;
  }
  async fetchMinified(params) {
    const url = `/campaigns-mini/${params.partner_id}`;
    const response = await Http.get(url, null, params);
    return response.body;
  }
}

export default new CampaignService();
