import * as feedsTypes from "../../helper/constant/types";

const INITIAL_STATE = {
    error: null,
    IsfetchingFeeds: false,
    Feeds: [],
    IsfetchingFeedsType: false,
    FeedsType: []
};

const Feed_Reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case feedsTypes.FETCH_FEED_START:
            return {
                ...state,
                IsfetchingFeeds: true
            };
        case feedsTypes.FETCH_FEED_SUCCESS:
            return {
                ...state,
                IsfetchingFeeds: true
            };
        case feedsTypes.FETCH_FEED_FAILURE:
            return {
                ...state,
                IsfetchingFeeds: false,
                error: action.payload
            };
        case feedsTypes.UPDATE_FEED:
            return {
                ...state,
                Feeds: action.payload
            };

        case feedsTypes.FETCH_FEED_TYPE_START:
            return {
                ...state,
                IsfetchingFeedsType: true
            };
        case feedsTypes.FETCH_FEED_TYPE_SUCCESS:
            return {
                ...state,
                IsfetchingFeedsType: true
            };
        case feedsTypes.FETCH_FEED_TYPE_FAILURE:
            return {
                ...state,
                IsfetchingFeedsType: false,
                error: action.payload
            };
        case feedsTypes.UPDATE_FEED_TYPE:
            return {
                ...state,
                FeedsType: action.payload
            };

        default:
            return { ...state };
    }
};

export default Feed_Reducer;
