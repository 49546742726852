import jwtDecode from "jwt-decode";
import { toastr } from "react-redux-toastr";
import Cookies from "js-cookie";
import Http from "./httpservice";
import { GET_ERRORS, SET_CURRENT_USER } from "../helper/constant/types";

export const login = (userData, onSuccess) => (dispatch) => {
    Http.post("login", userData)
        .then((user) => {
            if (user.body.success) {
                const { token, group } = user.body.data;
                Cookies.set("feed-balancer-token", token);
                const object = { token: token, timestamp: new Date().getTime() };
                localStorage.setItem('feed-balancer-token', JSON.stringify(object));
                const decoded = jwtDecode(token);
                decoded['group'] = group
                dispatch(setCurrentUser(decoded));
            } else {
                toastr.error("Error", user.body.error.message);
                dispatch({
                    type: GET_ERRORS,
                    payload: { message: "Feed balancer Server Failed", isError: true }
                });
                throw new Error(JSON.stringify(user.body.error));
            }
        })
        .catch((error) => {
            toastr.error("Error", "Feed balancer Server Failed");
            dispatch({
                type: GET_ERRORS,
                payload: { message: "Feed balancer Server Failed", isError: true }
            });
        });
}

export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const setLoginError = () => (dispatch) => {
    dispatch({
        type: GET_ERRORS,
        payload: { isError: false }
    });
};

export const logout = (callback) => async (dispatch) => {
    await localStorage.removeItem("feed-balancer-token");
    await Cookies.remove("feed-balancer-token");
    dispatch(setCurrentUser({}));
    callback();
};