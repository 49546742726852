import * as GeoGroupTypes from "../../helper/constant/types";

const INITIAL_STATE = {
    error: null,
    IsfetchingGeoGroup: false,
    GeoGroup: [],
    IsfetchingGeoList: false,
    GeoList: [],

};

const Geo_Group_List_Reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GeoGroupTypes.FETCH_GEO_GROUP_START:
            return {
                ...state,
                IsfetchingGeoGroup: true
            };
        case GeoGroupTypes.FETCH_GEO_GROUP_SUCCESS:
            return {
                ...state,
                IsfetchingGeoGroup: true
            };
        case GeoGroupTypes.FETCH_GEO_GROUP_FAILURE:
            return {
                ...state,
                IsfetchingGeoGroup: false,
                error: action.payload
            };
        case GeoGroupTypes.UPDATE_GEO_GROUP:
            return {
                ...state,
                GeoGroup: action.payload
            };


        case GeoGroupTypes.FETCH_GEO_LIST_START:
            return {
                ...state,
                IsfetchingGeoList: true
            };
        case GeoGroupTypes.FETCH_GEO_LIST_SUCCESS:
            return {
                ...state,
                IsfetchingGeoList: true
            };
        case GeoGroupTypes.FETCH_GEO_LIST_FAILURE:
            return {
                ...state,
                IsfetchingGeoList: false,
                error: action.payload
            };
        case GeoGroupTypes.UPDATE_GEO_LIST:
            return {
                ...state,
                GeoList: action.payload
            };

        default:
            return { ...state };
    }
};

export default Geo_Group_List_Reducer;
