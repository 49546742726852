import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import authReducer from "./authReducer";
import Geo_Group_List_Reducer from "./geoGroups/geo_groups.reducer";
import Feed_Reducer from "./feed/feed.reducer"
import Campaigns_Reducer from "./campaign/campaign.reducer"
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import ReduxToastr from 'react-redux-toastr';

const reducers = {
    auth: authReducer,
    toastr: toastrReducer,
    geo_group_list: Geo_Group_List_Reducer,
    feeds: Feed_Reducer,
    campaigns: Campaigns_Reducer
};
export default combineReducers(reducers);