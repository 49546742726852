export const GET_ERRORS = 'GET_ERRORS';
export const USER_LOADING = 'USER_LOADING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_OVERVIEW = 'SET_CURRENT_OVERVIEW';

export const DEVICES = [
  { label: 'Desktop', name: 'desktop', value: 'desktop' },
  { label: 'Tablet', name: 'tablet', value: 'tablet' },
  { label: 'Phone', name: 'phone', value: 'phone' },
  { label: 'Console', name: 'console', value: 'console' },
  { label: 'Wearable', name: 'wearable', value: 'wearable' },
  { label: 'TV', name: 'tv', value: 'tv' }
];

export const REDIRECT_TYPE = [
  { label: 'Server', name: 'server', value: 'server' },
  { label: 'Client', name: 'client', value: 'client' },
  { label: 'Client hide referer', name: 'client_hr', value: 'client_hr' },
  { label: 'Client hide referer with traking', name: 'client_hr_trk', value: 'client_hr_trk' },
  { label: 'Client with traking', name: 'client_trk', value: 'client_trk' },
]

export const OSES = [
  { label: 'Windows phone', name: 'windows_phone', value: 'windows_phone' },
  { label: 'Windows', name: 'windows', value: 'windows' },
  { label: 'Mac', name: 'mac', value: 'mac' },
  { label: 'Ios', name: 'ios', value: 'ios' },
  { label: 'Android', name: 'android', value: 'android' },
  { label: 'Blackberry', name: 'blackberry', value: 'blackberry' },
  { label: 'Chrome os', name: 'chrome_os', value: 'chrome_os' },
  { label: 'Kindle', name: 'kindle', value: 'kindle' },
  { label: 'Webos', name: 'webos', value: 'webos' },
  { label: 'Linux', name: 'linux', value: 'linux' },
  { label: 'Ps', name: 'ps', value: 'ps' },
  { label: 'Xbox', name: 'xbox', value: 'xbox' },
  { label: 'Nintendo', name: 'nintendo', value: 'nintendo' },
  { label: 'Bot', name: 'bot', value: 'bot' }
];

export const BROWSERS = [
  { label: 'Safari', name: 'safari', value: 'safari' },
  { label: 'Chrome', name: 'chrome', value: 'chrome' },
  { label: 'Internet Explorer', name: 'ie', value: 'ie' },
  { label: 'Firefox', name: 'firefox', value: 'firefox' },
  { label: 'Opera', name: 'opera', value: 'opera' },
  { label: 'Android', name: 'android', value: 'android' },
  { label: 'Bot', name: 'bot', value: 'bot' }
];

export const EVENTS = [
  { label: 'No Event', name: 'No Event', value: '' },
  { label: 'Impression', name: 'Impression', value: 'impression' },
  { label: 'regImpression', name: 'RegImpression', value: 'regImpression' },
  { label: 'Install', name: 'Install', value: 'install' },
  { label: 'Click', name: 'Click', value: 'click' },
  { label: 'Conversion', name: 'Conversion', value: 'conversion' },
  { label: 'Click-Conversion', name: 'Click-Conversion', value: 'click-conv' },
  { label: 'CPA_Conversion', name: 'CPA_Conversion', value: 'cpa_conversion' }
];

/**
 * Feeds balancer fraud Detection
 */
export const FEED_BALANCER_FRAUD_DETECTION_LIST = [
  { label: 'No Fraud Detection', name: 'No Fraud Detection', value: '' },
  { label: 'Anura - Direct', name: 'Anura - Direct', value: 'anura_direct' },
  { label: 'Anura - Tracking', name: 'Anura - Tracking', value: 'anura_trk' },
  { label: 'Anura', name: 'Anura', value: 'anura' },
  { label: 'Adkernel', name: 'Adkernel', value: 'adkernel' },
];

/**
 * Fraud Detection on conversion
 */
export const FRAUD_DETECTION_LIST = [
  { label: 'No Fraud Detection', name: 'No Fraud Detection', value: '' },
  { label: 'IPQS', name: 'IPQS', value: 'ipqs' },
  { label: 'Pixalate', name: 'Pixalate', value: 'pixalate' },
  { label: 'Internal', name: 'Internal', value: 'internal' },
];

export const GEOLOCATIONS = [
  { label: 'WW', name: 'World Wide', value: 'WW' },
  { label: 'A1', name: 'Anonymous Proxy', value: 'A1' },
  { label: 'US', name: 'United States', value: 'US' },
  { label: 'CA', name: 'Canada', value: 'CA' },
  { label: 'AF', name: 'Afghanistan', value: 'AF' },
  { label: 'AL', name: 'Albania', value: 'AL' },
  { label: 'DZ', name: 'Algeria', value: 'DZ' },
  { label: 'DS', name: 'American Samoa', value: 'DS' },
  { label: 'AD', name: 'Andorra', value: 'AD' },
  { label: 'AO', name: 'Angola', value: 'AO' },
  { label: 'AI', name: 'Anguilla', value: 'AI' },
  { label: 'AQ', name: 'Antarctica', value: 'AQ' },
  { label: 'AG', name: 'Antigua and/or Barbuda', value: 'AG' },
  { label: 'AR', name: 'Argentina', value: 'AR' },
  { label: 'AM', name: 'Armenia', value: 'AM' },
  { label: 'AW', name: 'Aruba', value: 'AW' },
  { label: 'AU', name: 'Australia', value: 'AU' },
  { label: 'AT', name: 'Austria', value: 'AT' },
  { label: 'AZ', name: 'Azerbaijan', value: 'AZ' },
  { label: 'BS', name: 'Bahamas', value: 'BS' },
  { label: 'BH', name: 'Bahrain', value: 'BH' },
  { label: 'BD', name: 'Bangladesh', value: 'BD' },
  { label: 'BB', name: 'Barbados', value: 'BB' },
  { label: 'BY', name: 'Belarus', value: 'BY' },
  { label: 'BE', name: 'Belgium', value: 'BE' },
  { label: 'BZ', name: 'Belize', value: 'BZ' },
  { label: 'BJ', name: 'Benin', value: 'BJ' },
  { label: 'BM', name: 'Bermuda', value: 'BM' },
  { label: 'BT', name: 'Bhutan', value: 'BT' },
  { label: 'BO', name: 'Bolivia', value: 'BO' },
  { label: 'BA', name: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'BW', name: 'Botswana', value: 'BW' },
  { label: 'BV', name: 'Bouvet Island', value: 'BV' },
  { label: 'BR', name: 'Brazil', value: 'BR' },
  { label: 'IO', name: 'British lndian Ocean Territory', value: 'IO' },
  { label: 'BN', name: 'Brunei Darussalam', value: 'BN' },
  { label: 'BG', name: 'Bulgaria', value: 'BG' },
  { label: 'BF', name: 'Burkina Faso', value: 'BF' },
  { label: 'BI', name: 'Burundi', value: 'BI' },
  { label: 'KH', name: 'Cambodia', value: 'KH' },
  { label: 'CM', name: 'Cameroon', value: 'CM' },
  { label: 'CV', name: 'Cape Verde', value: 'CV' },
  { label: 'KY', name: 'Cayman Islands', value: 'KY' },
  { label: 'CF', name: 'Central African Republic', value: 'CF' },
  { label: 'TD', name: 'Chad', value: 'TD' },
  { label: 'CL', name: 'Chile', value: 'CL' },
  { label: 'CN', name: 'China', value: 'CN' },
  { label: 'CX', name: 'Christmas Island', value: 'CX' },
  { label: 'CC', name: 'Cocos (Keeling Islands', value: 'CC' },
  { label: 'CO', name: 'Colombia', value: 'CO' },
  { label: 'KM', name: 'Comoros', value: 'KM' },
  { label: 'CG', name: 'Congo', value: 'CG' },
  { label: 'CK', name: 'Cook Islands', value: 'CK' },
  { label: 'CR', name: 'Costa Rica', value: 'CR' },
  { label: 'HR', name: 'Croatia (Hrvatska', value: 'HR' },
  { label: 'CU', name: 'Cuba', value: 'CU' },
  { label: 'CY', name: 'Cyprus', value: 'CY' },
  { label: 'CZ', name: 'Czech Republic', value: 'CZ' },
  { label: 'DK', name: 'Denmark', value: 'DK' },
  { label: 'DJ', name: 'Djibouti', value: 'DJ' },
  { label: 'DM', name: 'Dominica', value: 'DM' },
  { label: 'DO', name: 'Dominican Republic', value: 'DO' },
  { label: 'TP', name: 'East Timor', value: 'TP' },
  { label: 'EC', name: 'Ecudaor', value: 'EC' },
  { label: 'EG', name: 'Egypt', value: 'EG' },
  { label: 'SV', name: 'El Salvador', value: 'SV' },
  { label: 'GQ', name: 'Equatorial Guinea', value: 'GQ' },
  { label: 'ER', name: 'Eritrea', value: 'ER' },
  { label: 'EE', name: 'Estonia', value: 'EE' },
  { label: 'ET', name: 'Ethiopia', value: 'ET' },
  { label: 'FK', name: 'Falkland Islands (Malvinas', value: 'FK' },
  { label: 'FO', name: 'Faroe Islands', value: 'FO' },
  { label: 'FJ', name: 'Fiji', value: 'FJ' },
  { label: 'FI', name: 'Finland', value: 'FI' },
  { label: 'FR', name: 'France', value: 'FR' },
  { label: 'FX', name: 'France, Metropolitan', value: 'FX' },
  { label: 'GF', name: 'French Guiana', value: 'GF' },
  { label: 'PF', name: 'French Polynesia', value: 'PF' },
  { label: 'TF', name: 'French Southern Territories', value: 'TF' },
  { label: 'GA', name: 'Gabon', value: 'GA' },
  { label: 'GM', name: 'Gambia', value: 'GM' },
  { label: 'GE', name: 'Georgia', value: 'GE' },
  { label: 'DE', name: 'Germany', value: 'DE' },
  { label: 'GH', name: 'Ghana', value: 'GH' },
  { label: 'GI', name: 'Gibraltar', value: 'GI' },
  { label: 'GR', name: 'Greece', value: 'GR' },
  { label: 'GL', name: 'Greenland', value: 'GL' },
  { label: 'GD', name: 'Grenada', value: 'GD' },
  { label: 'GP', name: 'Guadeloupe', value: 'GP' },
  { label: 'GU', name: 'Guam', value: 'GU' },
  { label: 'GT', name: 'Guatemala', value: 'GT' },
  { label: 'GN', name: 'Guinea', value: 'GN' },
  { label: 'GW', name: 'Guinea-Bissau', value: 'GW' },
  { label: 'GY', name: 'Guyana', value: 'GY' },
  { label: 'HT', name: 'Haiti', value: 'HT' },
  { label: 'HM', name: 'Heard and Mc Donald Islands', value: 'HM' },
  { label: 'HN', name: 'Honduras', value: 'HN' },
  { label: 'HK', name: 'Hong Kong', value: 'HK' },
  { label: 'HU', name: 'Hungary', value: 'HU' },
  { label: 'IS', name: 'Iceland', value: 'IS' },
  { label: 'IN', name: 'India', value: 'IN' },
  { label: 'ID', name: 'Indonesia', value: 'ID' },
  { label: 'IR', name: 'Iran (Islamic Republic of', value: 'IR' },
  { label: 'IQ', name: 'Iraq', value: 'IQ' },
  { label: 'IE', name: 'Ireland', value: 'IE' },
  { label: 'IL', name: 'Israel', value: 'IL' },
  { label: 'IT', name: 'Italy', value: 'IT' },
  { label: 'CI', name: 'Ivory Coast', value: 'CI' },
  { label: 'JM', name: 'Jamaica', value: 'JM' },
  { label: 'JP', name: 'Japan', value: 'JP' },
  { label: 'JO', name: 'Jordan', value: 'JO' },
  { label: 'KZ', name: 'Kazakhstan', value: 'KZ' },
  { label: 'KE', name: 'Kenya', value: 'KE' },
  { label: 'KI', name: 'Kiribati', value: 'KI' },
  { label: 'KP', name: "Korea, Democratic People's Republic of", value: 'KP' },
  { label: 'KR', name: 'Korea, Republic of', value: 'KR' },
  { label: 'KW', name: 'Kuwait', value: 'KW' },
  { label: 'KG', name: 'Kyrgyzstan', value: 'KG' },
  { label: 'LA', name: "Lao People's Democratic Republic", value: 'LA' },
  { label: 'LV', name: 'Latvia', value: 'LV' },
  { label: 'LB', name: 'Lebanon', value: 'LB' },
  { label: 'LS', name: 'Lesotho', value: 'LS' },
  { label: 'LR', name: 'Liberia', value: 'LR' },
  { label: 'LY', name: 'Libyan Arab Jamahiriya', value: 'LY' },
  { label: 'LI', name: 'Liechtenstein', value: 'LI' },
  { label: 'LT', name: 'Lithuania', value: 'LT' },
  { label: 'LU', name: 'Luxembourg', value: 'LU' },
  { label: 'MO', name: 'Macau', value: 'MO' },
  { label: 'MK', name: 'Macedonia', value: 'MK' },
  { label: 'MG', name: 'Madagascar', value: 'MG' },
  { label: 'MW', name: 'Malawi', value: 'MW' },
  { label: 'MY', name: 'Malaysia', value: 'MY' },
  { label: 'MV', name: 'Maldives', value: 'MV' },
  { label: 'ML', name: 'Mali', value: 'ML' },
  { label: 'MT', name: 'Malta', value: 'MT' },
  { label: 'MH', name: 'Marshall Islands', value: 'MH' },
  { label: 'MQ', name: 'Martinique', value: 'MQ' },
  { label: 'MR', name: 'Mauritania', value: 'MR' },
  { label: 'MU', name: 'Mauritius', value: 'MU' },
  { label: 'TY', name: 'Mayotte', value: 'TY' },
  { label: 'MX', name: 'Mexico', value: 'MX' },
  { label: 'FM', name: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'MD', name: 'Moldova, Republic of', value: 'MD' },
  { label: 'MC', name: 'Monaco', value: 'MC' },
  { label: 'MN', name: 'Mongolia', value: 'MN' },
  { label: 'MS', name: 'Montserrat', value: 'MS' },
  { label: 'MA', name: 'Morocco', value: 'MA' },
  { label: 'MZ', name: 'Mozambique', value: 'MZ' },
  { label: 'MM', name: 'Myanmar', value: 'MM' },
  { label: 'NA', name: 'Namibia', value: 'NA' },
  { label: 'NR', name: 'Nauru', value: 'NR' },
  { label: 'NP', name: 'Nepal', value: 'NP' },
  { label: 'NL', name: 'Netherlands', value: 'NL' },
  { label: 'AN', name: 'Netherlands Antilles', value: 'AN' },
  { label: 'NC', name: 'New Caledonia', value: 'NC' },
  { label: 'NZ', name: 'New Zealand', value: 'NZ' },
  { label: 'NI', name: 'Nicaragua', value: 'NI' },
  { label: 'NE', name: 'Niger', value: 'NE' },
  { label: 'NG', name: 'Nigeria', value: 'NG' },
  { label: 'NU', name: 'Niue', value: 'NU' },
  { label: 'NF', name: 'Norfork Island', value: 'NF' },
  { label: 'MP', name: 'Northern Mariana Islands', value: 'MP' },
  { label: 'NO', name: 'Norway', value: 'NO' },
  { label: 'OM', name: 'Oman', value: 'OM' },
  { label: 'PK', name: 'Pakistan', value: 'PK' },
  { label: 'PW', name: 'Palau', value: 'PW' },
  { label: 'PA', name: 'Panama', value: 'PA' },
  { label: 'PG', name: 'Papua New Guinea', value: 'PG' },
  { label: 'PY', name: 'Paraguay', value: 'PY' },
  { label: 'PE', name: 'Peru', value: 'PE' },
  { label: 'PH', name: 'Philippines', value: 'PH' },
  { label: 'PN', name: 'Pitcairn', value: 'PN' },
  { label: 'PL', name: 'Poland', value: 'PL' },
  { label: 'PT', name: 'Portugal', value: 'PT' },
  { label: 'PR', name: 'Puerto Rico', value: 'PR' },
  { label: 'QA', name: 'Qatar', value: 'QA' },
  { label: 'RE', name: 'Reunion', value: 'RE' },
  { label: 'RO', name: 'Romania', value: 'RO' },
  { label: 'RU', name: 'Russian Federation', value: 'RU' },
  { label: 'RW', name: 'Rwanda', value: 'RW' },
  { label: 'KN', name: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'LC', name: 'Saint Lucia', value: 'LC' },
  { label: 'VC', name: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'WS', name: 'Samoa', value: 'WS' },
  { label: 'SM', name: 'San Marino', value: 'SM' },
  { label: 'ST', name: 'Sao Tome and Principe', value: 'ST' },
  { label: 'SA', name: 'Saudi Arabia', value: 'SA' },
  { label: 'SN', name: 'Senegal', value: 'SN' },
  { label: 'SC', name: 'Seychelles', value: 'SC' },
  { label: 'SL', name: 'Sierra Leone', value: 'SL' },
  { label: 'SG', name: 'Singapore', value: 'SG' },
  { label: 'SK', name: 'Slovakia', value: 'SK' },
  { label: 'SI', name: 'Slovenia', value: 'SI' },
  { label: 'SB', name: 'Solomon Islands', value: 'SB' },
  { label: 'SO', name: 'Somalia', value: 'SO' },
  { label: 'ZA', name: 'South Africa', value: 'ZA' },
  { label: 'GS', name: 'South Georgia South Sandwich Islands', value: 'GS' },
  { label: 'ES', name: 'Spain', value: 'ES' },
  { label: 'LK', name: 'Sri Lanka', value: 'LK' },
  { label: 'SH', name: 'St. Helena', value: 'SH' },
  { label: 'PM', name: 'St. Pierre and Miquelon', value: 'PM' },
  { label: 'SD', name: 'Sudan', value: 'SD' },
  { label: 'SR', name: 'Suriname', value: 'SR' },
  { label: 'SJ', name: 'Svalbarn and Jan Mayen Islands', value: 'SJ' },
  { label: 'SZ', name: 'Swaziland', value: 'SZ' },
  { label: 'SE', name: 'Sweden', value: 'SE' },
  { label: 'CH', name: 'Switzerland', value: 'CH' },
  { label: 'SY', name: 'Syrian Arab Republic', value: 'SY' },
  { label: 'TW', name: 'Taiwan', value: 'TW' },
  { label: 'TJ', name: 'Tajikistan', value: 'TJ' },
  { label: 'TZ', name: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'TH', name: 'Thailand', value: 'TH' },
  { label: 'TG', name: 'Togo', value: 'TG' },
  { label: 'TK', name: 'Tokelau', value: 'TK' },
  { label: 'TO', name: 'Tonga', value: 'TO' },
  { label: 'TT', name: 'Trinidad and Tobago', value: 'TT' },
  { label: 'TN', name: 'Tunisia', value: 'TN' },
  { label: 'TR', name: 'Turkey', value: 'TR' },
  { label: 'TM', name: 'Turkmenistan', value: 'TM' },
  { label: 'TC', name: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'TV', name: 'Tuvalu', value: 'TV' },
  { label: 'UG', name: 'Uganda', value: 'UG' },
  { label: 'UA', name: 'Ukraine', value: 'UA' },
  { label: 'AE', name: 'United Arab Emirates', value: 'AE' },
  { label: 'GB', name: 'United Kingdom', value: 'GB' },
  { label: 'UM', name: 'United States minor outlying islands', value: 'UM' },
  { label: 'UY', name: 'Uruguay', value: 'UY' },
  { label: 'UZ', name: 'Uzbekistan', value: 'UZ' },
  { label: 'VU', name: 'Vanuatu', value: 'VU' },
  { label: 'VA', name: 'Vatican City State', value: 'VA' },
  { label: 'VE', name: 'Venezuela', value: 'VE' },
  { label: 'VN', name: 'Vietnam', value: 'VN' },
  { label: 'VG', name: 'Virigan Islands (British', value: 'VG' },
  { label: 'VI', name: 'Virgin Islands (U.S.', value: 'VI' },
  { label: 'WF', name: 'Wallis and Futuna Islands', value: 'WF' },
  { label: 'EH', name: 'Western Sahara', value: 'EH' },
  { label: 'YE', name: 'Yemen', value: 'YE' },
  { label: 'YU', name: 'Yugoslavia', value: 'YU' },
  { label: 'ZR', name: 'Zaire', value: 'ZR' },
  { label: 'ZM', name: 'Zambia', value: 'ZM' },
  { label: 'ZW', name: 'Zimbabwe', value: 'ZW' },
  { label: 'AP', name: 'AP', value: 'AP' },
  { label: 'AX', name: 'AX', value: 'AX' },
  { label: 'BL', name: 'BL', value: 'BL' },
  { label: 'BQ', name: 'BQ', value: 'BQ' },
  { label: 'CW', name: 'CW', value: 'CW' },
  { label: 'EU', name: 'EU', value: 'EU' },
  { label: 'GG', name: 'GG', value: 'GG' },
  { label: 'IM', name: 'IM', value: 'IM' },
  { label: 'JE', name: 'JE', value: 'JE' },
  { label: 'MF', name: 'MF', value: 'MF' },
  { label: 'PS', name: 'PS', value: 'PS' },
  { label: 'SX', name: 'SX', value: 'SX' },
  { label: 'TL', name: 'TL', value: 'TL' },
  { label: 'SS', name: 'SS', value: 'SS' },
  { label: 'AS', name: 'AS', value: 'AS' },
  { label: 'CD', name: 'CD', value: 'CD' },
  { label: 'YT', name: 'YT', value: 'YT' },
  { label: 'ME', name: 'ME', value: 'ME' },
  { label: 'RS', name: 'RS', value: 'RS' }
];

export const macroData = [
  {
    parameter: 'Query',
    macro: '{{QUERY}}'
  },
  {
    parameter: 'Channel',
    macro: '{{CHANNEL}}'
  },
  {
    parameter: 'Geo',
    macro: '{{GEO}}'
  },
  {
    parameter: 'IP',
    macro: '{{IP}}'
  },
  {
    parameter: 'User Agent',
    macro: '{{UA}}'
  },
  {
    parameter: 'Refferal URL',
    macro: '{{REF}}'
  },
  {
    parameter: 'Feed Channel ID',
    macro: '{{FEEDCHID}}'
  },
  {
    parameter: 'Campaign ID',
    macro: '{{CAMPAIGNID}}'
  },
  {
    parameter: 'Hashed Campaign ID',
    macro: '{{HCID}}'
  },
  {
    parameter: 'Source',
    macro: '{{SOURCE}}'
  },
  {
    parameter: 'Click ID',
    macro: '{{CLKID}}'
  },
  {
    parameter: '*Yahoo only* market parameter',
    macro: '{{YHS_MARKET}}'
  },
  {
    parameter: 'Server URL',
    macro: '{{SERVER_URL}}'
  }
];

export const GEO_TYPE_EXCLUDE = 'exclude';
export const GEO_TYPE_INCLUDE = 'include';
export const SOURCE_TYPES = [
  { label: 'Numeric', name: 'Numeric', value: 'Numeric' },
  { label: 'String', name: 'String', value: 'String' },
  { label: 'StringCI', name: 'StringCI', value: 'StringCI' },
  { label: 'Mixed', name: 'Mixed', value: 'Mixed' },
  { label: 'MixedCI', name: 'MixedCI', value: 'MixedCI' }
];
export const fileTypes = ["PDF", "CSV", "XLS", "JPEG", "DOCX", "DOC", "XLSX", "TXT", "PPT", "PPTX", "JPG", "PNG", "GIF", "ZIP", "RAR", "7Z" , "APK"];
//role
export const ROLES = [
  {
    label: 'Super Admin',
    value: 'SuperAdmin'
  },
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'Management',
    value: 'management'
  },
  {
    label: 'Agent',
    value: 'agent'
  }
];

//geo group_list
export const FETCH_GEO_GROUP_START = 'FETCH_GEO_GROUP_START';
export const FETCH_GEO_GROUP_SUCCESS = 'FETCH_GEO_GROUP_SUCCESS';
export const FETCH_GEO_GROUP_FAILURE = 'FETCH_GEO_GROUP_FAILURE';
export const UPDATE_GEO_GROUP = 'UPDATE_GEO_GROUP';

export const FETCH_GEO_LIST_START = 'FETCH_GEO_LIST_START';
export const FETCH_GEO_LIST_SUCCESS = 'FETCH_GEO_LIST_SUCCESS';
export const FETCH_GEO_LIST_FAILURE = 'FETCH_GEO_LIST_FAILURE';
export const UPDATE_GEO_LIST = 'UPDATE_GEO_LIST';

//feed action
export const FETCH_FEED_START = 'FETCH_FEED_START';
export const FETCH_FEED_SUCCESS = 'FETCH_FEED_SUCCESS';
export const FETCH_FEED_FAILURE = 'FETCH_FEED_FAILURE';
export const UPDATE_FEED = 'UPDATE_FEED';

//feed type action
export const FETCH_FEED_TYPE_START = 'FETCH_FEED_TYPE_START';
export const FETCH_FEED_TYPE_SUCCESS = 'FETCH_FEED_TYPE_SUCCESS';
export const FETCH_FEED_TYPE_FAILURE = 'FETCH_FEED_TYPE_FAILURE';
export const UPDATE_FEED_TYPE = 'UPDATE_FEED_TYPE';

//campaigns section
export const FETCH_CAMPAIGNS_START = 'FETCH_CAMPAIGNS_START';
export const FETCH_CAMPAIGNS_SUCCESS = 'FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CAMPAIGNS_FAILURE = 'FETCH_CAMPAIGNS_FAILURE';
export const UPDATE_CAMPAIGNS = 'UPDATE_CAMPAIGNS';
