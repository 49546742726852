import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

// css
import classes from "./login.module.css";
// services
import { login } from "../../service/login.service";
import { auth } from "../../helper";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import Http from "../../service/httpservice";

import { GET_ERRORS, SET_CURRENT_USER } from "../../helper/constant/types";

// material ui
import { Button, Typography, TextField, Card } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useFormik } from "formik";
import crypto from "crypto-js";

const loginvalidationSchema = yup.object({
  email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(5, "Password should be of minimum 5 characters length")
    .required("Password is required")
});

const index = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate()
  const isAuthenticated = useSelector(auth.checkIfAuthenticated);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: loginvalidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      let { email, password } = values;
      const formData = {
        data: crypto.AES.encrypt(JSON.stringify({ email, password }), process.env.REACT_APP_CIPHER_SECRET).toString()
      };

      Http.post("login", formData)
        .then((user) => {
          setLoading(false);
          if (user.body.success) {
            const { token, group } = user.body.data;

            Cookies.set("feed-balancer-token", token);
            const object = { token: token, timestamp: new Date().getTime() };
            localStorage.setItem("feed-balancer-token", JSON.stringify(object));
            const decoded = jwtDecode(token);
            decoded["group"] = group;

            dispatch(setCurrentUser(decoded));
            toast.success("Welcome");
            // call(onSuccess);
          } else {
            toast.error(user.body.error.message);
            dispatch({
              type: GET_ERRORS,
              payload: { message: "Feed balancer Server Failed", isError: true }
            });
            // throw new Error(JSON.stringify(user.body.error));
          }
        })
        .catch((error) => {
          console.log("eror ", error);
          setLoading(false);
          toast.error("Feed balancer Server Failed");
          dispatch({
            type: GET_ERRORS,
            payload: { message: "Feed balancer Server Failed", isError: true }
          });
        });
    }
  });

  const setCurrentUser = (decoded) => {
    return {
      type: SET_CURRENT_USER,
      payload: decoded
    };
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <div className={classes.img_container}>
          <div>
            <h1>Hi, Welcome Back</h1>
            <img src="/static/illustrations/illustration_login.png" alt="login" />
          </div>
        </div>
      </Card>
      <div className={classes.form_container}>
        <div className={classes.form}>
          <Typography component="h1" variant="h3">
            Sign in to Imali CRM
          </Typography>
          <Typography sx={{ color: "text.secondary" }} style={{ margin: "8px 0 30px " }}>
            Enter your details below.
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <LoadingButton
              loading={loading}
              style={{ marginTop: "24px" }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
            >
              Login
            </LoadingButton>
          </form>
        </div>
      </div>
    </div>
  );
};

export default index;
