import React from 'react';

export const useSafeDispatch = (unSafeDispatch) => {
  const mountedRef = React.useRef(false);

  React.useLayoutEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const dispatch = React.useCallback(
    (...args) => {
      if (mountedRef.current) {
        unSafeDispatch(...args);
      }
    },
    [unSafeDispatch]
  );

  return dispatch;
};
