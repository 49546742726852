import React from "react";
import { useSafeDispatch } from "./useSafeDispatch";

function asyncReducer(state, action) {
  switch (action.type) {
    case "pending":
      return { status: "pending", data: null, error: null };
    case "resolved":
      return { status: "resolved", data: action.data, error: null };
    case "rejected":
      return { status: "rejected", data: null, error: action.error };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export const useAsync = (options = null) => {
  const [state, unSafeDispatch] = React.useReducer(asyncReducer, {
    status: "idle",
    data: null,
    error: null
  });

  const dispatch = useSafeDispatch(unSafeDispatch);
  const run = React.useCallback(
    (promise) => {
      if (!promise) {
        return;
      }
      dispatch({ type: "pending" });
      promise.then(
        (data) => {
          dispatch({ type: "resolved", data });
          if (options?.onSuccess && typeof options.onSuccess === "function") {
            options.onSuccess(data);
          }
        },
        (error) => {
          dispatch({ type: "rejected", error });
          if (options?.onError && typeof options.onError === "function") {
            options.onError(error);
          }
        }
      );
    },
    [dispatch]
  );

  return { ...state, run };
};
