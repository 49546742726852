import Http from "axios";
import { API_URL } from "../helper";

class Api {
  constructor() {
    Http.defaults.baseURL = API_URL;
    Http.defaults.timeout = 300000;
  }

  async get(resource, responseType = null, params = {}) {
    const { token } = JSON.parse(localStorage.getItem("feed-balancer-token"));
    const config = {
      responseType,
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    };

    try {
      const res = await Http.get(resource, config);
      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async destroy(resource, data) {
    const { token } = JSON.parse(localStorage.getItem("feed-balancer-token"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      data
    };
    try {
      const res = await Http.delete(resource, config);
      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async patch(resource, data) {
    const { token } = JSON.parse(localStorage.getItem("feed-balancer-token"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    };
    try {
      const res = await Http.patch(resource, data, config);
      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async post(resource, params, options = {}) {
    const parsedValue = localStorage.getItem("feed-balancer-token");
    let parsedToken;
    let token;
    if (parsedValue) {
      parsedToken = JSON.parse(localStorage.getItem("feed-balancer-token"));
      token = parsedToken.token;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    };
    if (options.headers && options.headers["Content-Type"]) {
      config.headers["Content-Type"] = options.headers["Content-Type"];
    }

    try {
      const response = await Http({ method: "post", url: resource, data: params, headers: config.headers });
      return this.successResponse(response);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async put(resource, data) {
    const { token } = JSON.parse(localStorage.getItem("feed-balancer-token"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    };
    try {
      const res = await Http.put(resource, data, config);
      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  errorResponse(error) {
    if (!error.response) {
      return this.response({ data: { message: "Network error" }, headers: error.request.headers });
    }

    switch (error.response.status) {
      case 401:
        window.location.replace("/#/login");
        break;
      default:
        if (!error.response.data.success) {
          return this.response(error.response);
        }
        return this.response({
          data: "Network Error",
          success: false
        });
    }
  }

  successResponse(response) {
    return this.response(response);
  }

  response({ data, status, headers }) {
    return {
      body: data,
      status,
      headers
    };
  }
}

export default new Api();
